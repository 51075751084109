import React from 'react';

import Layout from '../components/layout';



const Thanks = () => {


  return (
    <Layout>
<section className="light-back1">
    <h1>Thanks You </h1>
    <p>We will get back to you as soon as possible.</p>
</section>
    </Layout>
  );
};

export default Thanks;